import { createSelector } from 'reselect';

export default createSelector(
    [(state) => state.plp?.listing?.error, (state) => state.plp?.listing?.response, (state) => state.config, (state, props) => props],
    getSelectedCategoryInternal,
);

export function getSelectedCategoryInternal(error, response, config, routeKey) {
    if (!response || !response.body || error) {
        return null;
    }
    //strip any ignored categories from config like '/list' or '/designer'
    const ignoreCategories = config?.head?.plp?.facet?.ignoreCategories || [];
    const categories = getSelectedCategories(response.body.selectedCategory);
    const selectedCategories = categories.filter((category) => !ignoreCategories.includes(category.seoURLKeyword));

    if (routeKey === 'SEARCH' || routeKey === 'MENSSEARCH') {
        return {
            selectedCategories: [
                {
                    label: response?.body?.searchTermView?.searchTerm || '',
                    seoURLKeyword: response?.body?.searchTermView?.seoURLKeyword?.replace(/\s+/g, '+') || '',
                    identifier: 'SEARCH',
                },
            ].concat(selectedCategories),
            pageType: 'SEARCH',
        };
    }

    const pageType = getPageType(categories);

    return {
        selectedCategories,
        pageType,
    };
}

//flatten selectedCategory
export function getSelectedCategories(selectedCategory) {
    const selectedCategories = [];
    if (!selectedCategory) {
        return selectedCategories;
    }

    selectedCategories.push({
        label: selectedCategory.label,
        identifier: selectedCategory.identifier,
        canonical: selectedCategory.canonical,
        seoURLKeyword: selectedCategory.seo && selectedCategory.seo.seoURLKeyword,
        seo: selectedCategory.seo,
        attributes: selectedCategory.attributes,
    });

    if (selectedCategory.child) {
        return [...selectedCategories, ...getSelectedCategories(selectedCategory.child)];
    }
    return selectedCategories;
}

function getPageType(categories) {
    const selectedCategories = categories.filter(
        (c) => !c.attributes?.find((attr) => attr.identifier === 'CAT_ATTR_TAXONOMY')?.values?.find((v) => v.identifier === 'TAXONOMY_MENS'),
    );
    const attributes = selectedCategories.length > 0 && selectedCategories[0].attributes ? selectedCategories[0].attributes : [];
    const pageTypeAttr = attributes.find((attr) => attr.identifier === 'CAT_ATTR_PAGE_TYPE');
    let pageType = pageTypeAttr && pageTypeAttr.values && pageTypeAttr.values[0].identifier;

    // TODO: remove this after API starts sending us MARK_DOWN_DESIGNER attribute for sale DLPs
    if (pageType === 'DESIGNER' && selectedCategories[0].seoURLKeyword.split('/')[1] === 'sale') {
        pageType = 'MARK_DOWN_DESIGNER';
    }

    return pageType || 'STANDARD';
}
