import { constructUrl } from '@ynap/url-configuration';
import { raygun } from '@ynap/clientside-monitoring';
import queryString from 'query-string';

export default function getHeaderAndSEOData(categories, pageType, messages, config, country) {
    if (!categories || !categories.length) {
        raygun.helpers.agent('send', {
            error: new Error(`getHeaderAndSEOData error`),
            tags: [`error-getHeaderAndSEOData`, `categories are not defined`],
        });
        return {};
    }

    // consider removing this switch and replacing with a ternary if we aren't expanding on this
    switch (pageType) {
        case 'SEARCH':
            return getSearchHeaderAndSEOData(categories, pageType, messages, config);
        default:
            return getDefaultHeaderAndSEOData(categories, pageType, messages, country);
    }
}

export const getTitle = (categories) => (categories.length >= 1 ? categories[0].label : '');

export function getDefaultHeaderAndSEOData(categories, pageType, messages, country) {
    const depth = categories.length;
    const subTitle = categories.length > 1 ? categories[depth - 1].label : '';
    const title = getTitle(categories);
    const seo = getSeo(categories, depth);
    const isSalePage = pageType === 'MARK_DOWN';
    return {
        title: isSalePage ? messages?.contentHeader?.sale({ country }) : title,
        subTitle,
        seo,
        pageType,
    };
}

export function getSearchHeaderAndSEOData(categories, pageType, messages, config) {
    // find the search terms
    const searchTermCategoryIndex = categories.findIndex((cat) => cat.identifier === 'SEARCH');
    const searchTermCategory = categories[searchTermCategoryIndex];
    const preTitle = config?.head?.plp?.subTitleAsTitle?.includes(pageType) ? null : messages.search.title();
    const subTitle = categories.length > searchTermCategoryIndex ? categories[searchTermCategoryIndex + 1]?.label : null;
    const title = messages.search.results({ searchTerm: searchTermCategory.label });
    const seo = getSeo(categories, categories.length);
    return {
        title,
        preTitle,
        subTitle,
        seo,
        pageType,
    };
}

export const getSeo = (categories, depth) => {
    let seo = categories[depth - 1]?.seo || {};

    seo.default = {
        title: categories[0].label,
        subTitle: categories.length > 1 ? categories[depth - 1]?.label : '',
    };

    return seo;
};

export const getCanonicalUrl = (config, locale, headerAndSEOData, pageNumber = '1', canonical) => {
    const search = {};
    if (headerAndSEOData?.contentTop?.facetContent) {
        search.facet = headerAndSEOData?.contentTop?.ynapParameter;
    }
    search.pageNumber = pageNumber > 1 ? pageNumber : undefined; // avoid double canonical for pageNumber 1
    const query = queryString.stringify(search, { encode: false });
    return headerAndSEOData
        ? `${config.head.seo.baseUrl}${constructUrl(config.global.urlConfig.plp.construct, {
              LOCALE: locale,
              SEO_URLKEYWORD: canonical ? canonical : headerAndSEOData?.seo?.seoURLKeyword,
          })}${query ? `?${query}` : ''}`
        : '';
};

export const getOgImage = (image) => {
    if (!image) {
        return {};
    }
    return {
        image: image.imageUrl,
        'image:width': image.width,
        'image:height': image.height,
    };
};

const getFullTitle = (seo, prefixConfig, locale) => {
    // Temp fix for SEOPF-431
    const selectedPrefix =
        prefixConfig?.mens?.url && seo?.seoURLKeyword && seo.seoURLKeyword.startsWith(prefixConfig?.mens.url) ? prefixConfig?.mens : prefixConfig?.womens;
    const lang = locale.slice(0, 2);

    if (selectedPrefix?.[lang] && seo?.title) {
        return seo?.title.includes(selectedPrefix[lang]) ? seo?.title : `${selectedPrefix[lang]} ${seo?.title}`;
    }
    return seo?.title;
};

export const getMetaData = (
    config,
    locale,
    headerAndSEOData,
    pageNumber,
    noIndex = false,
    productsToPreload = [],
    contentSEO,
    contentImagesToPreload = [],
    canonical,
) => {
    const { seo = {}, banners = undefined, contentTop } = headerAndSEOData;
    const { default: seoDefault = {}, metaDescription: seoMetaDescription = undefined, metaKeyword: seoMetaKeyword = undefined } = seo;
    const { subTitle: seoSubtitle, title: seoTitle } = seoDefault;

    const { prefix } = config.head.seo;

    const { title = '', metaDescription = '', metaKeyword = '' } = contentSEO ?? {};

    const fullTitle = (contentTop?.facetContent && title.trim()) || getFullTitle(seo, prefix, locale);
    const description = (contentTop?.facetContent && metaDescription.trim()) || seoMetaDescription;
    const keywords = (contentTop?.facetContent && metaKeyword.trim()) || seoMetaKeyword;

    return {
        titles: [seoSubtitle, seoTitle].filter(Boolean),
        fullTitle,
        brandId: config.head.theme,
        description,
        keywords,
        og: headerAndSEOData.seo && {
            title: fullTitle,
            description,
            type: 'product.group',
            ...getOgImage(banners),
        },
        robots: noIndex ? 'noindex,follow' : 'index,follow',
        canonicalUrl: getCanonicalUrl(config, locale, headerAndSEOData, pageNumber, canonical),
        toPreload: getPreloadData(productsToPreload, config, contentImagesToPreload),
    };
};

export const getPreloadData = (productsToPreload, config, contentImagesToPreload) => {
    let toPreload = [];
    if (contentImagesToPreload?.length) {
        toPreload.push(...contentImagesToPreload);
    }

    if (productsToPreload?.length) {
        productsToPreload.map((product) => {
            const { mobileSrcset, desktopSrcset } = product?.imgSrc?.primaryPlpSrcset || {};
            desktopSrcset &&
                mobileSrcset &&
                toPreload.push(
                    { href: product.imgSrc.primary, imagesrcset: desktopSrcset, as: 'image', media: '(min-width: 1024px)', fetchpriority: 'high' },
                    { href: product.imgSrc.primary, imagesrcset: mobileSrcset, as: 'image', media: '(max-width: 1023px)', fetchpriority: 'high' },
                );
        });
    }

    return toPreload;
};
